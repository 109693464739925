
import $app from '@/plugins/modules'
import { Common } from '@/common'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IExternalMessageTemplate } from '../types';

@Component
export default class MessageTemplatesList extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => [] })
  readonly items: Array<IExternalMessageTemplate> | undefined;

  common = $app.module(Common);

  get lang(): string {
    return this.common.$store.appLanguage.code
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  append() {
    this.$emit('append')
  }

  select(item: IExternalMessageTemplate) {
    this.$emit('select', item)
  }

  remove(item: IExternalMessageTemplate) {
    this.$emit('remove', item)
  }
}
